//==============================================================================
//  Curved Corners
//==============================================================================

.curve {
	position: absolute;
	display: block;
	width: 5px;
	height: 5px;
	overflow: hidden;
	z-index: 1000;
	background-repeat: no-repeat;
}

.white {
	background-image: url('../images/corners-white.gif');
}

.blue {
	background-image: url('../images/corners-blue.gif');
}

.grey {
	background-image: url('../images/corners-grey.gif');
}

.bl {
	bottom: -1px;
	left: -1px;
	background-position: 0 -5px;
}

.br {
	bottom: -1px;
	right: -1px;
	background-position: -7px -5px;
}

form .bl,
form .br {
	bottom: 0px;
}

.tl {
	top: -1px;
	_top: 0px;
	left: -1px;
	background-position: 0 0;
}

.tr {
	top: -1px;
	_top: 0px;
	right: -1px;
	background-position: -7px 0;
}
