//==============================================================================
//  Tables
//==============================================================================

.datagrid-1 tr td {
	padding: 10px;
	text-align: center;
	vertical-align: middle;
      border: 1px solid silver;
}

.datagrid-1 tr td.overview {
	text-align: left;
}

tr.line td {
	background: #F6F6F6;
}

.datagrid-1 td.cover-toggle {
	width: 150px;
}

.datagrid-1 td.cover-info {
	width: 200px;
}

.no-choice td.cover-info {
	width: 250px;
}

.datagrid-1 td.cover-extra {
	width: 130px;
}

.datagrid-1 td.blackout {
	background: #E1E1E1;
}

.quote-details th,
.quote-details td {
	text-align: left;
}

.quote-details th {
	width: 233px;
	padding: 0 20px 20px 0;
}

.cover-price {
	clear: left;
	display: block;
}
