//==============================================================================
//  Typograpy
//==============================================================================

/* Headings */
h1 {
	font-size: 2.2em;
	line-height: 1.2em;
	font-weight: bold;
	margin: 0 0 0.8em;
	color: #808080;
}

#header h1 {
	float: left;
	margin: 0;
	padding: 38px 0 0 64px;
	color: #0046AD;
	font-weight: normal;
	background: url("../images/slash.png") top left no-repeat;
}

h2 {
	font-size: 1.4em;
	line-height: 1.26em;
	font-weight: bold;
	margin: 0.4em 0 0.6em;
	color: #006CBA;
}

.boxheader h2 {
	float: left;
	color: #FFFFFF;
}

h3 {
	font-size: 0.92em;
	line-height: 1.33em;
	font-weight: bold;
	margin: 0.4em 0 0.6em;
	color: #FFFFFF;
}

.asidebox h3 {
	margin: 0;
	padding: 6px 10px;
	background: #51AD32;
	border-radius: 5px;
}

form h3 {
	display: inline-block;
	color: #0046AD;
	font-size: 1em;
}

#footermenu h3 {
	color: #0046AD;
}

/* Typography */
strong {
	font-weight: bold;
}

p {
	font-size: 1em;
	line-height: 1.4em;
	margin: 0 0 0.6em;
}

.block p {
	color: #FFFFFF;
	font-size: 1.2em;
	line-height: 1.2em;
}

.block-1 p {
	border-bottom: 2px solid #ff171d;
}

.block-2 p {
	border-bottom: 2px solid #4d78e6;
}

.block-3 p {
	border-bottom: 2px solid #19c319;
}

.block-4 p {
	border-bottom: 2px solid #caaf44;
}

.group-links p {
	float: right;
	margin: 0.5em 0;
}

.asidebox p {
	margin: 5px;
}

span.tel-number {
	font-weight: bold;
	font-size: 1.2em;
}

.error {
	color: #B51F26;
}

/* Links */

a {
	font-size: 1em;
	line-height: 1.4em;
	color: #0046AD;
}

#drop-down a {
	color: #FFFFFF;
	text-decoration: none;
}

#drop-down ul a {
	display: block;
	padding: 5px 0 5px 20px;
	border-bottom: 1px solid #091f57;
	background: url("../images/arrow-right.png") left no-repeat;
}

#drop-down ul a:hover {
}

#drop-down .visit {
	float: right;
	padding: 5px 20px 5px 0;
	background: url("../images/arrow-visit.png") right center no-repeat;
}

#tab {
	position: absolute;
	top: -1px;
	right: 0px;
	width: 170px;
	padding: 5px 10px;
	color: #FFFFFF;
	background: #163179;
	text-align: left;
	text-decoration: none;
}

.headerInner a {
	float: left;
	margin: 15px 26px 15px 0px;
	cursor: pointer;
}

ul.font-size li a {
	color: #103184;
	text-decoration: none;
}

ul.font-size li a:hover {
	text-decoration: underline;
}

ul.font-size li #decrease {
	font-size: 0.76em;
}

ul.font-size li #reset {
	font-size: 1.07em;
}

ul.font-size li #increase {
	font-size: 1.38em;
}

a.content-toggler {
	float: right;
	margin: 10px 0;
	color: #FFFFFF;
}

#footermenu a {
	color: #545454;
}

#footermenu a:hover {
	text-decoration: underline;
}

.print-link {
	float: left;
	width: 100%;
	margin-bottom: 10px;
	text-align: right;
}
