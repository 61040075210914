//==============================================================================
//  Resets and Defualts
//==============================================================================

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Body */
html {
	font-size: 62.5%;
}

body {
	min-width: 990px;
	color: #545454;
	background: #ECECED;
	font-size: 1.3em;
	font-family: arial, helvetica, sans-serif;
}

.clear {
    clear: both;
}

/* Site Structure */
#page {
	width: 990px;
	margin: 0 auto;
}

#wrapper {
	float: left;
	width: 988px;
	background: #FFFFFF;
	border: 1px solid #CED4E2;
}

.pageInner {
	padding: 0 20px 30px 20px;
}

.equal-col {
	clear: right;
	width: 330px;
}

.floatLeft {
	float: left;
}

.floatRight {
	float: right;
}

.hidden {
    display: none;
}
