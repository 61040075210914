//==============================================================================
//  Footer
//==============================================================================

#footermenu {
	float: left;
	clear: both;
	width: 988px;
	_width: 990px;
}

#footermenu .footer-top {
	float: left;
	width: 988px;
	_width: 990px;
	border-top: 1px solid #666666;
	border-bottom: 1px solid #666666;
}

#footermenu .footer-top div {
	float: left;
	padding: 20px;
}

#footermenu a {
	text-decoration: none;
}

#footermenu .footer-top .social-links {
	border-left: 1px solid #CCCCCC;
}

#footermenu .footer-top .world-links {
	float: right;
}

#footermenu .group-links {
	clear: left;
	width: 976px;
	min-height: 28px;
	_height: 28px;
	padding: 5px 7px 4px;
	background: #DBDBDB url("../images/template/footer.png") repeat-x;
	overflow: hidden;
}



//==============================================================================
//  Footer: Redesign 2012
//==============================================================================

.footer {
    background: #DADADA;
    background: -moz-linear-gradient(top, #CED4E2 0%, #D6D6D6 1%, #E7E7E7 6%, #E9E9E9 7%, #EBEBEB 23%, #EBEBEB 31%, #DADADA 100%);
    background: -webkit-linear-gradient(top, #CED4E2 0%,#D6D6D6 1%,#E7E7E7 6%,#E9E9E9 7%,#EBEBEB 23%,#EBEBEB 31%,#DADADA 100%);
    background: -o-linear-gradient(top, #CED4E2 0%,#D6D6D6 1%,#E7E7E7 6%,#E9E9E9 7%,#EBEBEB 23%,#EBEBEB 31%,#DADADA 100%);
    background: -ms-linear-gradient(top, #CED4E2 0%,#D6D6D6 1%,#E7E7E7 6%,#E9E9E9 7%,#EBEBEB 23%,#EBEBEB 31%,#DADADA 100%);
    background: linear-gradient(to bottom, #CED4E2 0%,#D6D6D6 1%,#E7E7E7 6%,#E9E9E9 7%,#EBEBEB 23%,#EBEBEB 31%,#DADADA 100%);
}

.footer {
    border-top-color: white;
}

.footer {
    width: 100%;
    border-top: 1px solid;
    padding: 25px 0 15px;
    position: relative;
    /* z-index: 1000000; */
}

.footer .footerContent, .qabProgressBar a {
    color: white;
}

.footer .footerContent {
    width: 950px;
    margin: 0 auto;
}

.footer .footerContent p {
    color: #666;
}

.footer .footerContent p {
    font-size: 1.1em;
    line-height: 1.5;
    text-align: left;
    padding-bottom: 10px;
}
