//==============================================================================
//  Content
//==============================================================================

#content {
  float: left;
  width: 750px;
  padding: 10px 0 0 0;
}

#right {
  float: right;
  width: 190px;
  overflow: hidden;
  padding: 48px 0 0 0;
}

#right .rightInner {
  padding: 57px 3px 0 0;
}

.asidebox {
  position: relative;
  width: 178px;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.asideboxheader {
    color: white;
    background: #103184;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: block;
    zoom: 1;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1.4em;
}

.boxheader {
  float: left;
  width: 690px;
  padding: 0 10px;
  background: #113184;
  border-radius: 5px 5px 0px 0px;
}

.closed {
  margin-bottom: 20px;
  border-radius: 5px;
}

.alertMessages {
  margin-bottom: 20px;
}

.content-toggle {
  display: inline-block;
  _float: left;
  _clear: left;
  _width: 670px;
  margin-bottom: 20px;
  padding: 20px;
  background: #DCEBF8;
  border-radius: 0px 0px 5px 5px;
}

.hidden {
  display: none;
}

.direct-debit-details {
  width: 335px;
}

div.direct-debit-details {
  float: right;
}

address.direct-debit-details {
  float: left;
}

.frame {
  width: 200px;
  margin-bottom: 30px;
  padding: 3px 5px;
  background: #FFFFFF;
  border: 1px solid black;
}

.boxcontents ul {
  margin-left: 1rem;
}
.boxcontents ul li > ul {
  margin-top: 10px;
  margin-left: 2rem;
  list-style-type: circle;
}
.boxcontents li p {
  margin: 10px auto;
}