//==============================================================================
//  Forms
//==============================================================================

form {
	float: left;
	position: relative;
	margin-bottom: 30px;
	padding: 10px 20px 20px;
	background: #F6F6F6;
	border-radius: 5px;
}

fieldset,
#complete {
	display: inline-block;
	background: #DCEBF8;
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 5px;
}

.step-2 fieldset {
	padding: 0;
}

.step-4 fieldset {
	float: left;
}

table.standardForm {
	width: 670px;
}

.standardForm th,
.standardForm td {
	width: 50%;
}

.standardForm th {
	padding-bottom: 10px;
	font-weight: bold;
	text-align: left;
}

label {
	margin-bottom: 10px;
	font-weight: bold;
}

td label {
	display: inline-block;
	_float: left;
	_clear: left;
}

.cover-toggle label {
	display: block;
	_width: 150px;
	text-align: center;
}

.direct-debit-setup label {
	float: left;
	width: 50%;
}

.direct-debit-setup .account-holder-labels {
	float: left;
}

.direct-debit-setup .account-holder-labels label {
	clear: left;
	width: auto;
}

input.text,
select.select,
textarea {
	display: inline-block;
	margin-bottom: 10px;
	padding: 5px;
	border-radius: 5px;
}

input.text,
select.select,
textarea{
	border: 1px solid #B6B6B6;
}

input.text:focus,
select.select:focus,
textarea:focus {
	border: 1px solid #666666;
}

textarea {
	width: 660px !important;
	font-family: arial, helvetica, sans-serif;
	font-size: 1em;
}

input#sortCode1,
input#sortCode2,
input#sortCode3 {
	width: 35px !important;
}

input.error,
select.error {
	border: 1px solid #B51F26;
}
