//==============================================================================
//  Buttons
//==============================================================================

.text-button {
    background-color: transparent;
    text-decoration: underline;
    font-weight: bold;
    padding-top: 4px;
    color: #113184;
	  cursor: pointer;
    border:0
}

.linkBtn,
.linkBtnRed,
.formSubmit {
    display: inline-block;
    line-height: normal;
    font-weight: normal;
    background: #0D2B77;
    overflow: visible;
    border: 1px solid #fff;
    font-family: Arial, sans serif;
}

.linkBtn a:link,
.linkBtn a:visited,
.linkBtn span,
.linkBtn .form-action,
.linkBtnRed .submit,
.linkBtn .address {
    display: inline-block;
    background: url('../images/linkBtn.png') no-repeat 100% 50%;
    border: 0;
    color: #FFF!important;
    text-decoration: none;
    padding: 8px 40px 8px 10px;
    margin: 0;
    overflow: visible;
}

.button {
	display: inline-block;
	margin-bottom: 10px;
	padding: 5px 10px;
	border-radius: 5px;
	border: none;
	font-size: 1.2em;
	line-height: 1.4em;
	text-decoration: none;
	color: #FFFFFF;
}

.button:hover {
	cursor: pointer;
}

.address {
	margin-bottom: 10px;
	/*background: url("../images/form-button-blue.png") center no-repeat;*/
}

.form-action {
	float: right;
	/*background: url("../images/form-button-blue.png") center no-repeat;*/
}

.submit {
	float: right;
	/*background: url("../images/form-button-red.png") center no-repeat;*/
}

.back {
	float: left;
	/*background: url("../images/form-button-blue.png") center no-repeat;*/
}

.help {
	display: inline-block;
	width: 18px;
	height: 18px;
	margin-left: 20px;
	font-size: 0px;
	color: #DCEBF8;
	background: url("../images/icons/help.png") center no-repeat;
}

/* Overriding Styles with New code on Live solution */
.linkBtn,
.formSubmit {
  background: #314A8B;
  background: -moz-linear-gradient(top, #314A8B 0%, #0D2B77 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #314A8B), color-stop(100%, #0D2B77)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #314A8B 0%, #0D2B77 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #314A8B 0%, #0D2B77 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #314A8B 0%, #0D2B77 100%); /* IE10+ */
  background: linear-gradient(to top, #314A8B 0%, #0D2B77 100%); /* W3C */
}

.linkBtn:hover,
.linkBtn:active,
.formSubmit:hover,
.formSubmit:active {
  background: #6b7dac;
  background: -moz-linear-gradient(top, #6b7dac 0%, #334c8c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6b7dac), color-stop(100%, #334c8c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #6b7dac 0%, #334c8c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #6b7dac 0%, #334c8c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #6b7dac 0%, #334c8c 100%); /* IE10+ */
  background: linear-gradient(to top, #6b7dac 0%, #334c8c 100%); /* W3C */
}

/* Red */
.linkBtnRed,
.formSubmitRed {
  background: #EC1A3B;
  background: -moz-linear-gradient(top, #EC1A3B 0%, #CF001C 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #EC1A3B), color-stop(100%, #CF001C)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #EC1A3B 0%, #CF001C 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #EC1A3B 0%, #CF001C 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #EC1A3B 0%, #CF001C 100%); /* IE10+ */
  background: linear-gradient(to top, #EC1A3B 0%, #CF001C 100%); /* W3C */
}

.linkBtnRed:hover,
.linkBtnRed:active,
.formSubmitRed:hover,
.formSubmitRed:active {
  background: #f04660;
  background: -moz-linear-gradient(top, #f04660 0%, #d72b42 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f04660), color-stop(100%, #d72b42)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f04660 0%, #d72b42 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f04660 0%, #d72b42 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f04660 0%, #d72b42 100%); /* IE10+ */
  background: linear-gradient(to top, #f04660 0%, #d72b42 100%); /* W3C */
}

.button {
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  font-size: 1.2em;
  line-height: 1.4em;
  text-decoration: none;
  color: #FFFFFF;
}

.button:hover {
  cursor: pointer;
}