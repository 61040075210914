//==============================================================================
//  Lists
//==============================================================================

ul {
	list-style-type: disc;
}

#drop-down ul {
	list-style: none;
}

ul.font-size {
	list-style: none;
	text-align: right;
}

ul.font-size li {
	display: inline;
	padding: 0 0 0 13px;
	font-family: "lucida sans unicode", "lucida grande", sans-serif;
}

ul.qabProgressBar {
	width: 100%;
	margin: 0 0 25px 0;
	padding: 0;
	background-color: #ffffff;
	overflow: hidden;
	list-style: none;
}

ul.qabProgressBar li {
    float: left;
    min-width: 105px;
    height: 16px;
    margin: 0;
    padding: 10px 18px 10px 0;
    _padding: 10px 20px 5px 0;
    display: inline-block;
    color: #FFFFFF;
    background-color: #cdcfd0;
    background-image: url("../images/progress-bar.png");
    background-position: 100% 0;
    background-repeat: no-repeat;
}

ul.qabProgressBar li.active {
	background-color: transparent;
	background-position: 100% -36px;
}

ul.qabProgressBar li.traversed {
	background-color: transparent;
	background-position: 100% -72px;
}

ul.qabProgressBar li.activeLast {
    background-position: 100% -144px;
}

ul.qabProgressBar li.inactiveLast {
    background-position: 100% -108px;
}

.alertMessages ul li {
	list-style-position: inside;
}

ul.pdf-docs {
	list-style: none;
	margin-bottom: 10px;
}

ul.pdf-docs li {
	min-height: 20px;
	padding: 17px 0 15px 50px;
	background: url("../images/icons/pdf.gif") top left no-repeat;
}

form ul li,
#complete ul li {
	margin-bottom: 10px;
}

#footermenu ul {
}

#footermenu ul li {
	margin-bottom: 10px;
}

ul.numbers {
	margin-bottom: 30px;
}

ul.numbers li {
	display: inline;
	margin: 0 5px 0 0;
	padding: 3px 5px;
	background: #FFFFFF;
	border: 1px solid black;
}

.alertMessages ul li {
	margin-left: 30px;
	color: #B51F26;
}

.alertMessages ul li.alertHeader {
	margin-left: 0px;
	padding-left: 25px;
	list-style: none;
	background: url("../images/icons/error.gif") left no-repeat;
}
