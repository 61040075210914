#drop-down {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  background: #163178;
}

.drop-down-outer {
  float: left;
  width: 100%;
  background: #1a3782;
  border-top: 1px solid #0a2361;
  border-bottom: 1px solid #0a2361;
}

.drop-down-outer-hidden {
    padding-top: 0;
    margin-top: 0;
}

.drop-down-inner {
  width: 990px;
  margin: 0 auto;
}

.drop-down-content {
  float: left;
  width: 950px;
  padding: 0 20px;
  position: relative;
  display: none;
}

.block {
  float: left;
  width: 221px;
  margin: 20px 0px;
  padding: 2px;
  background: #16327a;
  border: 1px solid #0a2361;
}

.block-1,
.block-2,
.block-3 {
  margin-right: 14px;
}

.block div {
  float: left;
  width: 199px;
  padding: 10px;
  border: 1px solid #0a2361;
}
