//==============================================================================
//  Gradients
//==============================================================================

/* Link button colour variations */
/* Default */
.linkBtn,
.formSubmit
{
    background: #314A8B;
    background: -moz-linear-gradient(top, #314A8B 0%, #0D2B77 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#314A8B), color-stop(100%,#0D2B77)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #314A8B 0%,#0D2B77 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #314A8B 0%,#0D2B77 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #314A8B 0%,#0D2B77 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #314A8B 0%,#0D2B77 100%); /* W3C */
}
.linkBtn:hover,
.linkBtn:active,
.formSubmit:hover,
.formSubmit:active
{
    background: #6b7dac;
    background: -moz-linear-gradient(top, #6b7dac 0%, #334c8c 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6b7dac), color-stop(100%,#334c8c)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #6b7dac 0%,#334c8c 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #6b7dac 0%,#334c8c 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #6b7dac 0%,#334c8c 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #6b7dac 0%,#334c8c 100%); /* W3C */
}

/* Red */
.linkBtnRed,
.formSubmitRed
{
    background: #EC1A3B;
    background: -moz-linear-gradient(top, #EC1A3B 0%, #CF001C 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#EC1A3B), color-stop(100%,#CF001C)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #EC1A3B 0%,#CF001C 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #EC1A3B 0%,#CF001C 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #EC1A3B 0%,#CF001C 100%); /* IE10+ */
    background: linear-gradient(to bottom  #EC1A3B 0%,#CF001C 100%); /* W3C */
}
.linkBtnRed:hover,
.linkBtnRed:active,
.formSubmitRed:hover,
.formSubmitRed:active
{
    background: #f04660;
    background: -moz-linear-gradient(top, #f04660 0%, #d72b42 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f04660), color-stop(100%,#d72b42)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #f04660 0%,#d72b42 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #f04660 0%,#d72b42 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #f04660 0%,#d72b42 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #f04660 0%,#d72b42 100%); /* W3C */
}
