//==============================================================================
//  Box Shadows
//==============================================================================

.linkBtn,
.linkBtnRed {
    -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.linkBtn:active {
    -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.linkBtn .back {
	background: url('../images/linkBtn-back.png') no-repeat 0 50% !important;
	padding: 8px 10px 8px 40px !important;
}
