
//==============================================================================
//  Help me : Redesign 2012
//==============================================================================

.helpMePlaceholder {
    clear: both;
    width: 100%;
}

.helpMeWrapper .helpMe {
    background: white;
    border-color: #95BB88;
    -o-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid;
    font-size: 1.3em;
    color: #787266;
}

.helpMeWrapper, .helpMeWrapper.fixed {
    background: white;
}

.helpMeWrapper {
    width: 968px;
    padding: 10px 10px 20px 10px;
}

.helpMeWrapper .helpTitle {
    color: #2C7711;
    font-weight: bold;
}

.helpMeWrapper .helpNo {
    color: #0B276D;
    font-weight: bold;
}

.helpMeWrapper.fixed {
    position: fixed;
    bottom: 0;
    z-index: 500;
    padding-bottom: 20px;
}
