//==============================================================================
//  Header
//==============================================================================
#header {
  height: 1%;
  position: relative;
  text-align: right;
}

#header .headerInner {
  width: 100%;
  overflow: hidden;
  margin: 0 0 14px 0;
}

#header .headerBar {
  background: url("../images/template/header-bar.gif") no-repeat;
  min-height: 26px;
  _height: 26px;
  overflow: hidden;
  padding: 8px 17px 6px;
}
