//==============================================================================
//  Ticks / Crosses
//==============================================================================

p.tick,
p.cross {
	float: left;
	width: 100%;
	height: 21px;
	font-size: 0px;
	background-position: center;
	background-repeat: no-repeat;
}

p.tick {
	background-image: url("../images/icons/tick.png");
	_background-image: url("../images/icons/tick-ie.png");
}

p.cross {
	background-image: url("../images/icons/cross.png");
	_background-image: url("../images/icons/cross-ie.png");
}

p.tick,
p.cross {
	_color: #DCEBF8;
	_background-position: 37px -42px;
}

.line p.tick,
.line p.cross {
	_color: #F6F6F6;
	_background-position: 37px 0;
}

.blackout p.tick,
.blackout p.cross {
	_color: #E1E1E1;
	_background-position: 37px -21px;
}

table {
	font-size: 1em;
	line-height: 1.4em;
}

label {
	font-size: 1em;
	line-height: 1.4em;
}

.mark-1 {
	color: #B30000;
}

.mark-2 {
	font-size: 1.8em;
	color: #B30000;
}

ul, li {
	font-size: 1em;
	line-height: 1.4em;
}

ul.font-size li {
	line-height: 1.6em;
}

ul.qabProgressBar li {
	font-size: 0.9em;
	line-height: 1.36em;
}

ul.qabProgressBar li span {
    color: #091f57;
    text-align: center;
    display: block;
    float: left;
    width: 28px;
    height: 22px;
    border-radius: 14px;
    background: #ffffff;
    padding-top: 4px;
    margin: -5px 10px auto 5px;
    _margin-right: 5px;
    _margin-left: 2px;
}

.alertMessages ul li {
	line-height: 1.6em;
}

address {
	font-size: 1em;
	line-height: 1.4em;
	margin: 0 0 0.6em;
}
